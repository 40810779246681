@import "./css/lang.css";

.backgroundImage {
    width: 100%;
    height: 85vh;

    background-color: darkgray;
    background-size: cover;
    background-position: center;

    position: relative;
}

.blurEffect {
    width: 100%;
    height: 100%;

    background-image: url("./../../images/code.jpg");
    background-size: cover;
    background-position: center;

    filter: blur(3px);

    position: absolute;

    top: 0;
    left: 0;
    z-index: 1;
}

.overBlur {
    position: relative;
    z-index: 2;
}

.personal-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin-left: 8vw;
}

.initTitle {
    text-align: center;
    margin-right: 3vw;
}

.initTitle h1, p {
    color: white;
}

.initTitle h1 {
    margin-top: 0;
    font-size: 5vw;
}

.initTitle p {
    font-size: 2.5vw;
}

.my-photo {
    margin-right: 3vw;
    margin-top: 5vw;
}

.my-photo img {
    width: 20vw;
    border-radius: 50px;

    box-shadow: 0 10px 30px purple;
    border: 5px ridge purple;

    transition: box-shadow 0.3s ease-in-out;
}

.my-photo:hover img {
    box-shadow: 0 20px 50px purple;
}

.personal-info {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 800px) {
    .personal-info-wrapper {
        flex-direction: column;
    }

    .my-photo img {
        width: 80vw;
        max-width: 300px;
        box-shadow: 0 5px 25px purple;
        border: none;
    }

    .backgroundImage {
        /*height: 100vh;*/
        height: 850px;
    }

    .personal-info {
        margin-top: 25px;
        flex-direction: column;
    }

    .initTitle h1 {
        font-size: 45px;
    }

    .initTitle p {
        font-size: 23px;
    }
}
