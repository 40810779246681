@import "./welcome/index.css";
@import "./projects/index.css";
@import "./skills/index.css";
@import "./projects_list/index.css";
@import "./footer/index.css";


.dynamic-underline {
    transition: font-weight 0.5s ease;
    position: relative;
}

.dynamic-underline:hover {
    font-weight: bold;
}

.dynamic-underline::after {
    content: '';
    left: 0;
    bottom: -0.1vw;
    height: 2px;
    width: 100%;
    background-color: white;

    position: absolute;

    transform: scaleX(0);
    transform-origin: left;

    transition: transform 0.2s ease-out;
}

.dynamic-underline:hover::after {
    transform: scaleX(1);
}

.section-title {
    display: inline-block;
    width: auto;
    cursor: default;

    font-size: 2.5vw;

    font-weight: normal;
}

.section-title::after {
    background-color: red;
    height: 3px;
    bottom: -0.2vw;
}

.back-button {
    text-decoration: none;
    font-size: 2vw;

    display: inline-block;
    width: auto;

    font-weight: normal;
}

.back-button::after {
    background-color: #15b5a9;
    height: 5px;
    bottom: -0.4vw;
}

.link-underline {
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
}

.link-underline::after {
    background-color: #1bc80f;
    height: 3px;
    bottom: -0.2vw;
}


.smooth-appear {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1.0s ease-out, transform 1.0s ease-out;
}

.sm-fast {
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.sm-medium {
    transition: opacity 1.0s ease-out, transform 1.0s ease-out;
}
.sm-slow {
    transition: opacity 1.4s ease-out, transform 1.4s ease-out;
}

.smooth-appear.show {
    opacity: 1;
    transform: translateY(0);
}


@media only screen and (max-width: 800px) {
    .section-title {
        font-size: 27px;
    }

    .back-button {
        margin-top: 18px;
        font-size: 20px;
    }
}