@import "./css/card.css";
@import "./css/hovers.css";

.projects-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-list-wrapper h1 {
    font-size: 3.5vw;
}

.projects-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.project-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .projects-list-wrapper h1 {
        font-size: 23px;
    }
}
