.project-section-wrapper {
    max-width: 30vw;
    margin: 10vh 3vw 5vh;
}

.project-section-wrapper h1 {
    font-weight: bold;
}

.project-links-wrapper {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
}

.link-wrapper {
    display: flex;
    align-items: center;

    margin-top: 2vh;
    min-width: 15vw;
}

.link-wrapper img {
    width: 30px;
    height: 100%;
}

@media only screen and (max-width: 800px) {
    .project-section-wrapper {
        max-width: 85vw;
        margin: 5px 3vw 5px;
    }
    .project-section-wrapper div {
        font-size: 18px;
    }
    .project-section-wrapper h1 {
        font-size: 22px;
    }

    .project-links-wrapper {
        margin-bottom: 18px;
    }
}
