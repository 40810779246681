.language-skill {
    display: flex;
    flex-direction: row;

    max-width: 25vw;

    margin-bottom: 4vw;
}

.lang-1 {
    display: flex;
    flex-direction: column;
}

.lang-2 {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    margin-left: 10px;
}

.lang-3 {
    text-align: center;
}


.language-skill p {
    color: black;
}

.language-skill h3 {
    font-weight: bold;
    font-size: 1.5vw;
}

.language-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
}

@media only screen and (max-width: 800px) {
    .language-skill {
        margin-bottom: 26px;
        max-width: 100vw;
    }

    .language-skill h3 {
        font-size: 17px;
    }

    .lang-1 {
        margin-left: 5px;
    }

    .language-icon img {
        width: 60px;
    }
}