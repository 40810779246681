.footer-container {
    padding-bottom: 10vh;
    padding-top: 7vh;
    margin-top: 15vh;
    margin-bottom: 0;

    background-color: lightgray;
}

.footer-arrow {
    position: absolute;
    padding-bottom: 0;
    left: 50%;
    transform: translate(-50%, 0) rotate(-90deg);

    background-color: rgba(0, 0, 0, 0);
    border: none;

    cursor: pointer;

    transition: transform 0.2s ease-in;
}

.footer-arrow img {
    width: 2.5vw;
    height: 100%;
}

.footer-arrow:hover {
    transform: translate(-50%, -5px) rotate(-90deg);
}

.footer-title {
    width: 25vw;
}

.footer-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-left: 5vw;
    margin-right: 10vw;
}

.footer-contact {
    display: flex;
    flex-direction: row;

    margin-top: 10px;
}

.footer-contact img {
    width: 25px;
}

.footer-contact div, a {
    margin-left: 1vw;
}

.footer-contact a {
    text-decoration: none;
    transition: font-weight 0.3s ease-in;
}

.footer-contact a:hover {
    font-weight: bold;
}

.footer-logo {
    width: 30vw;
    filter: drop-shadow(0px 0px 0px #22a69c);
    transition: filter 0.3s ease-in;
}

.footer-logo:hover {
    filter: drop-shadow(2px 2px 25px #0bcdbf);
}

@media only screen and (max-width: 800px) {
    .footer-info {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-bottom: 26px;
    }

    .footer-title {
        width: 80vw;
        text-align: center;
    }

    .footer-logo {
        margin-top: 27px;
        width: 200px;
    }

    .footer-arrow img {
        width: 35px;
    }
}
