@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=McLaren&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap');

@import "./pages/index.css";
@import "./components/index.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  font-size: 1.2vw;
  font-family: "Montserrat", 'sans-serif';
  color: black;
}

html {
  background-color: #e4e4e4;
}

@media only screen and (max-width: 800px) {
  * {
    font-size: 15px;
  }
}


.metal-mania-regular {
  font-family: "Metal Mania", system-ui;
  font-weight: 400;
  font-style: normal;
}

.mclaren-regular {
  font-family: "McLaren", system-ui;
  font-weight: 400;
  font-style: normal;
}

.montecarlo-regular {
  font-family: "MonteCarlo", cursive;
  font-weight: 400;
  font-style: normal;
}


::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  margin-right: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
