@import "./css/languageSkills.css";
@import "./css/education.css";
@import "./css/hobby.css";

.skills-wrapper {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.skill {
    margin: 1vw 2vw 1vw;
    max-width: 30vw;
}

@media only screen and (max-width: 800px) {
    .skills-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .skill {
        max-width: 80vw;
    }
}
