.change-language-wrapper {
    margin-top: 2vw;
    margin-right: 4vw;
    margin-left: 6vw;

    max-width: 80vw;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.language-button {
    border: none;
    background: none;
    padding: 0;

    cursor: pointer;
}

.change-lang-button-wrapper {
    margin-right: 5vw;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.lang-option {
    margin: 0;
    font-size: 1.8vw;
    font-weight: normal;
}

@media only screen and (max-width: 800px) {
    .language-button {
        margin-top: 18px;
    }

    .language-button p {
        font-size: 18px;
    }

    .lang-option {
        border-bottom: 2px solid white;
    }
}
